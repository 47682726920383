import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";
// import HomeView from "../views/HomeView.vue";
import { defineAsyncComponent } from "vue";

const router = createRouter({
  history: createWebHashHistory(),  // hash 模式
  // history: createWebHistory(), // history 模式
  routes: [
    {
      path: "/",
      name: "home",
      component: defineAsyncComponent(() => import(`../views/HomeView.vue`)),
      meta: {
        title: "大灣區體檢",
        keepAlive: true,
      },
    },
    {
      path: "/wdxq",
      name: "wangdian",
      component: defineAsyncComponent(() => import(`../views/WangDian.vue`)),
      meta: {
        // title: "网点详情",
      },
    },
    {
      path: "/yyxm",
      name: "yuyue",
      component: defineAsyncComponent(() => import(`../views/YvYue.vue`)),
      meta: {
        // title: "预约项目",
      },
    },
    {
      path: "/yyfw",
      name: "pointperson",
      component: defineAsyncComponent(() => import(`../views/PointPerson.vue`)),
      meta: {
        // title: "预约服务",
      },
    },
    {
      path: "/yvyue",
      name: "yvyue",
      component: defineAsyncComponent(() =>
        import(`../views/YvyueResoult.vue`)
      ),
      meta: {
        // title: "预约结果",
      },
    },
    {
      path: "/yycx",
      name: "noorder",
      component: defineAsyncComponent(() => import(`../views/NoOrder.vue`)),
      meta: {
        // title: "预约结果",
      },
    },
    {
      path: "/search",
      name: "orderview",
      component: defineAsyncComponent(() => import(`../views/OrderView.vue`)),
      meta: {
        // title: "预约查询",
      },
    },
    {
      path: "/aboutUs",
      name: "aboutus",
      component: defineAsyncComponent(() => import(`../views/AboutUs.vue`)),
      meta: {
        // title: "关于我们",
      },
    },
    {
      path: "/popolicy",
      name: "popolicy",
      component: defineAsyncComponent(() => import(`../views/PoPolicy.vue`)),
      meta: {
        // title: "隐私政策",
      },
    },
    {
      path: "/usercon",
      name: "usercon",
      component: defineAsyncComponent(() => import(`../views/UserCon.vue`)),
      meta: {
        // title: "用户协议",
      },
    },
    {
      path: "/news",
      name: "news",
      component: defineAsyncComponent(() => import(`../views/NewsPage.vue`)),
      meta: {
        // title: "用户协议",
      },
    },
    {
      path: "/workersearch",
      name: "workersearch",
      component: defineAsyncComponent(() => import(`../views/worker/WorkerSearch.vue`)),
      meta: {
        // title: "查询",
      },
    },
    {
      path: "/WorkerSearchResult",
      name: "WorkerSearchResult",
      component: defineAsyncComponent(() => import(`../views/worker/WorkerSearchResult.vue`)),
      meta: {
        // title: "查询结果",
      },
    },
    {
      path: "/OrderSearch",
      name: "OrderSearch",
      component: defineAsyncComponent(() => import(`../views/OrderSearch.vue`)),
      meta: {
        title: "预约查询",
        keepAlive: true,
      },
      // children:[
      //   {
      //     path: "OrderDetail",
      //     name: "OrderDetail",
      //     component: () => import(`../views/OrderDetail.vue`),
      //     meta: {
      //       title: "预约详情",
      //       keepAlive: true,
      //     },
      //   }
      // ]
    },
    {
      path: "/OrderDetail",
      name: "OrderDetail",
      component: () => import(`../views/OrderDetail.vue`),
      meta: {
        title: "预约详情",
      },
    },
    {
      path: "/*",
      redirect: "/",
    },
  ],
});

// // 全局路由守卫
// router.beforeEach((to, from, next) => {
//   // console.log(to, from)
//   if (to.meta.title) {
//     document.title = `${to.meta.title}`;
//   }
//   next();
// });

// router.afterEach((to, from) => {
//   // console.log(to, from)
//   console.log("afterEach");
// });

export default router;

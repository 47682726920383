const zhCN = {
    messages:{
        "gohome":'返回首页',
        "upload":'上传',
        'orderSearch':'预约查询',
        'userProtocol':'用户协议',
        'privacyPolicy':'隐私政策',
        'aboutUs':'关于我们',
        'companyNumber1':'粤ICP备2023102858号-1',
        'search':'搜索',
        'searchServe':'搜索服务',
        'searchServeType':'选择服务类型',
        'searchHint0':'使用手机号/预约码搜索，手机号前添加+86或+852',
        'searchHint1':'搜索英文姓氏',
        'searchServiceCategory':'选择服务类',
        'searchService':'选择服务',
        'home': '首页',
        'outlet': '服务网点',
        'outletInfo': '网点信息',
        'outletXq': '网点详情',
        'outletDesc': '网点介绍',
        'outletAddress': '网点地址',
        'yyfw': '预约服务',
        'yydxq': '预约单详情',
        'orderNumber': '订单号',
        'cardNumber': '证件号',
        'orderCode': '预约码',
        'uploadPay': '上传支付证明',
        'uploadClick': '点击上传',
        'searchcx': '查询',
        'placeOrder': '请输入要查询的订单号',
        'placeCard': '请输入预约人身份证号',
        'conUser': '联系人',
        'conMobile': '联系电话',
        'yyUserinfo': '预约人信息',
        'yuyue': '预约',
        'yyUser': '预约人',
        'yyUserHint': '预约人（个人信息必须与证件号相符）',
        'yyTime': '预约时间',
        'sex1': '男',
        'sex2': '女',
        'docCheck': '医生检查单',
        'docCheckHint': '医生检查单(最多3个文件)',
        'confirm': '确定',
        'cancel': '取消',
        'noSearchHint': '未查询到相关信息！',
        'alipay': '支付宝',
        'paypal': 'PayPal',
        'offline': '线下支付',
        'firstname': '英文姓氏',
        'lastname': '英文名字',
        'gender': '性别',
        'birthday': '出生日期',
        'carid': '香港身份证或往来港澳通行证',
        'referral': '医生转介信发出日期',
        'referralHint': '请选择医生转介信发出日期',
        'lastnameHint': '请输入英文姓氏',
        'firstnameHint': '请输入英文名字',
        'genderHint': '选择性别',
        'birthdayHint': '选择出生日期',
        'caridHint': '香港身份证A123456(7)，请输入A1234567',
        'mobileHint': '请输入联系电话',
        'mobileCodeGet': '获取验证码',
        'mobileCode': '验证码',
        'mobileCodeHint': '输入验证码',
        'extend': '识别码',
        'extendHint': '请输入识别码(选填)',
        'clearHistory': '清除记录',
        'yyProject': '预约项目',
        'project': '项目',
        'hospital': '医管局转介',
        'selectTime': '选择时间',
        'addService': '添加服务',
        'checkAgree': '我已阅读并同意',
        'checkAgreeClick': '隐私政策、有关收集个人资料的目的、版权告示及免责明',
        'checkAgreeClickMsg': '请阅读并同意隐私政策、有关收集个人资料的目的、版权告示及免责明',
    },
    order:{
        'status0': '待支付',
        'status1': '审核中',
        'status2': '审核失败',
        'status3': '已支付',
        'status4': '已完成',
        'yyoutlet': '预约网点',
        'reason': '原因',
        'hint1': '您的订单尚未支付',
        'hint2': '付款信息正在审核中',
        'hint3': '请您在预约时间前往预约网点获取服务',
        'hint4': '服务已完成',
        'orderInfo': '订单信息',
        'createTime': '下单时间',
        'payTime': '支付时间',
        'payChannel': '支付方式',
        'payChannelHint': '选择支付方式',
        'total': '合计',
        'price0': '市场价',
        'price1': '医管局转介价',
        'mustPay': '应付',
        'searchTotal': '共找到',
        'searchAppend': '个服务',
        'searchOutletAppend': '家服务网点',
        'yySuccess': '预约成功',
        'yyFailed': '预约失败',
        'yySuccessHint': '请在预约时间前往对应服务网点获取服务！',
        'yyOfflineSuccessHint1': '请根据下方提示及时支付款项，',
        'yyOfflineSuccessHint2': '并于预约时间前往指定的服务网点获取服务！',
        'payInfo': '付款信息',
        'yyMoney': '金额',
        'toOrderDetail': '查看订单详情',
    }
}

export default zhCN

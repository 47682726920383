
<script setup>
import { RouterLink, RouterView } from 'vue-router'
import navHeader from './components/navHeader.vue';
// import HomePage from './components/HomePage.vue';
import nFooter from './components/nFooter.vue';
import {computed, onMounted, watch} from "vue";
import { useStore } from "@/store";
import {useI18n} from "vue-i18n";
const { locale } = useI18n();
let store = useStore()
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    console.log(callback)
    callback = debounce(callback, 16);
    super(callback);
  }
}

const language = computed(() => store.state.app.language);

watch(language, (newLang, oldLang) => {
  locale.value =  newLang
})

onMounted(async () => {
  store.commit('app/RESET_LANGUAGE', '')
  locale.value =  language.value
  let screenWidth = document.body.clientWidth
  setScreen(screenWidth)
  window.onresize = ()=>{
    let screenWidth = document.body.clientWidth
    setScreen(screenWidth)
  }
});

function setScreen(screenWidth){
  if (screenWidth >= 1280){
    store.commit('app/UPDATE_SCREEN_LEVEL', 1)
  }else if (screenWidth < 1100){
    store.commit('app/UPDATE_SCREEN_LEVEL', 3)
  }else{
    store.commit('app/UPDATE_SCREEN_LEVEL', 2)
  }
}

</script>
<template>
  <div id="page-body">
    <!-- <div> -->
    <nav-header></nav-header>
    <div class="main-content">
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component class="view" :is="Component" />
        </keep-alive>
      </router-view>
    </div>
    <!-- </div> -->
    <div class="foot">
      <n-footer></n-footer>
    </div>
  </div>
</template>

<style lang="less">
#page-body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: rgba(225, 225, 225, 0.1);
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
}

.main-content{
  width: 100%;
  padding-bottom: 160px;
}

.foot {
  width: 100%;
  position: absolute;
  z-index: 1;
  bottom: 0;
}

.content {
  // min-height: 83vh;
}

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>

<template>
    <div class="header">
        <div class="nav">
            <div class="left">
                <div class="logo">
                    <img src="../assets/img/logo.png" alt="" style="position: relative;width: 120px; cursor: pointer" @click="goHome">
                </div>
            </div>
            <dl>
                <dt class="dt" v-if="selo" @click="orderDetailClick">{{ $t('messages.orderSearch') }}</dt>
                <dt v-if="!selo" style="cursor: pointer;" class="order-focus" @click="orderDetailClick">{{ $t('messages.orderSearch') }}</dt>
                <!-- 用户协议 -->
                <!-- <dt>{{ $t('messages.userProtocol') }}</dt> -->
                <dt class="dt" v-if="selu && screen != 3" @click="user">{{ $t('messages.userProtocol') }}</dt>
                <dt v-if="!selu && screen != 3" style="cursor: pointer;" class="order-focus" @click="user">{{ $t('messages.userProtocol') }}</dt>
                <!-- 隐私政策 -->
                <!-- <dt>{{ $t('messages.privacyPolicy') }}</dt> -->
                <dt class="dt" v-if="sels && screen != 3" @click="stytem">{{ $t('messages.privacyPolicy') }}</dt>
                <dt v-if="!sels && screen != 3" style="cursor: pointer;" class="order-focus" @click="stytem">{{ $t('messages.privacyPolicy') }}</dt>
                <!-- 关于我们 -->
                <!-- <dt>{{ $t('messages.aboutUs') }}</dt> -->
                <dt class="dt" v-if="selw && screen != 3" @click="aboutUs">{{ $t('messages.aboutUs') }}</dt>
                <dt v-if="!selw && screen != 3" style="cursor: pointer;" class="order-focus" @click="aboutUs">{{ $t('messages.aboutUs') }}</dt>
                <!-- <dt @click="changeLang">文体</dt> -->
                <dt v-if="self" @click="changeLang">
                    <span v-if="language == 'zhCN'" style="display: flex;justify-content: center;align-items: self-end;">
                        <div class="jian">
                            <span>简</span>
                        </div>
                        <div class="fan">
                            <span>繁</span>
                        </div>
                    </span>
                    <span v-if="language == 'zhCT'" style="display: flex;justify-content: center;align-items: self-end;">
                        <div class="jian">
                            <span>繁</span>
                        </div>
                        <div class="fan">
                            <span>简</span>
                        </div>
                    </span>
                </dt>
            </dl>
        </div>
    </div>
</template>

<script setup>
import router from '@/router';
import {computed, ref} from 'vue';
import { useStore } from '@/store';
import { onMounted } from 'vue';
const store = useStore();
const path = ref('')
path.value = router.currentRoute.value.fullPath
const language = computed(() => store.state.app.language);
const screen = computed(() => store.state.app.screen_level);
const selo = ref('true')
const selu = ref('true')
const sels = ref('true')
const selw = ref('true')
const self = ref('true')

onMounted(() => {
    if (path.value == '/') {
        selo.value = true,
            selu.value = true,
            sels.value = true,
            selw.value = true,
            self.value = true
    }
})

function orderDetailClick() {
    selo.value = false
    selu.value = true
    sels.value = true
    selw.value = true
    router.push({ path: '/OrderSearch' })
}
function changeLang() {
    if (language.value === 'zhCT') {
        store.commit('app/UPDATE_LANGUAGE', 'zhCN')
    } else {
      store.commit('app/UPDATE_LANGUAGE', 'zhCT')
    }
}
function goHome() {
    selu.value = true
    selo.value = true
    sels.value = true
    selw.value = true
    router.push({ path: '/' })
}
function user() {
    selu.value = false
    selo.value = true
    sels.value = true
    selw.value = true
    router.push({ path: 'usercon' })
}
function stytem() {
    sels.value = false
    selu.value = true
    selo.value = true
    selw.value = true
    router.push({ path: '/popolicy' })
}
function aboutUs() {
    selw.value = false
    selu.value = true
    selo.value = true
    sels.value = true
    router.push({ path: '/aboutUs' })
}
</script>
<style>
.jian {
    width: 20px;
    line-height: 19px;
    padding: 0px;
    background: #E5C782;
    border: 0.96px solid rgba(229, 199, 130, 1);
    border-radius: 3.84px;
    cursor: pointer;

}

.jian span {
    font-size: 12px;
    color: #FFFFFF;
    position: relative;
    /* right: 5px; */
}

.fan {
    width: 12px;
    line-height: 12px;
    padding: 1px;
    cursor: pointer;
    background: #FFFFFF;
    border: 0.72px solid rgba(229, 199, 130, 1);
    border-radius: 2.4px;

}

.fan span {
    font-size: 7.2px;
    color: #E5C782;
    position: relative;
    /* right: 9px; */
}

.header {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid rgba(237, 237, 237, 1);
}

.nav {
    width: 86%;
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left {
    width: auto;
    padding: 8px 0;

}

.logo {
    height: 2.43rem;
}

dl {
    width: auto;
    display: flex;
    justify-content: space-between;
}

dt {
    font-size: 14px;
    padding: 0px 12px;
    text-align: center;
    line-height: 28px;
    max-height: 28px;
    box-sizing: border-box;
    margin: 0 2px;
    /* max-width: 6rem; */
    overflow: hidden;
    font-family: 'PingFangSC-Regular';
    color: #333333;
    letter-spacing: 0;
    font-weight: 400;
}

.dt {
    cursor: pointer;
    box-sizing: border-box;
}

.dt:hover {
    border: 1px solid rgba(229, 199, 130, 1);
    border-radius: 4px;
    color: #E5C782;
}

.dt:active {
    border: 1px solid rgba(229, 199, 130, 1);
    border-radius: 4px;
    color: #E5C782;
}

.dt:focus {
    border: 1px solid rgba(229, 199, 130, 1);
    border-radius: 4px;
    color: #E5C782;
}

.dt:focus-visible {
    border: 1px solid rgba(229, 199, 130, 1);
    border-radius: 4px;
    color: #E5C782;
}

.order-focus {
    border: 1px solid rgba(229, 199, 130, 1);
    border-radius: 4px;
    color: #E5C782;
}
</style>

export default {
  namespaced: true,
  state: {
    screen_level: 1,
    language: 'zhCT'
  },
  mutations: {
    UPDATE_SCREEN_LEVEL (state, level) {
      state.screen_level = level
    },
    UPDATE_LANGUAGE (state, lang) {
      localStorage.setItem('language', lang)
      state.language = lang
    },
    RESET_LANGUAGE (state, lang) {
      lang = localStorage.getItem("language")
      if (!lang){
        lang = 'zhCT'
      }
      localStorage.setItem("language", lang)
      state.language = lang
    },
  },
  actions: {
    updateScreen ({ commit }, level) {
      commit('UPDATE_SCREEN_LEVEL', level)
    },
    updateLanguage ({ commit }, lang) {
      commit('UPDATE_LANGUAGE', lang)
    },
  }
}

import { createStore, useStore as baseUseStore } from "vuex";
import app from './modules/app'

export default createStore({
  state: {
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    app
  },
});

export function useStore(){
  return baseUseStore()
}

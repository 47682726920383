import { createApp } from "vue";
import App from "./App.vue";
import router from "../src/router/index";
// import axios from "axios";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "./assets/css/breadcrumb.css";

import zhCn1 from "element-plus/dist/locale/zh-cn.mjs";

import I18n from "@/lang/i18n.js";

const app = createApp(App);
// app.config.globalProperties.$api = axios;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
createApp(App)
  .use(ElementPlus, { locale: zhCn1 })
  .use(store)
  .use(router)
  .use(I18n)
  .mount("#app");

router.beforeEach((to, from, next) => {
  if (to.path === "/") {
    next();
  } else {
    next();
  }
});

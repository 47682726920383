const zhCT = {
  messages: {
    "gohome": '返回首頁',
    "upload": '上傳',
    'orderSearch': '預約查詢',
    'userProtocol': '用戶協議',
    'privacyPolicy': '隱私政策',
    'aboutUs': '關於我們',
    'companyNumber1': '粵ICP備2023102858號-1',
    'search': '搜索',
    'searchServe': '搜索服務',
    'searchServeType': '選擇服務類型',
    'searchHint0': '使用手機號/預約碼搜索，手機號前添加+86或+852',
    'searchHint1': '搜索英文姓氏',
    'searchServiceCategory': '選擇服務類',
    'searchService': '選擇服務',
    'home': '首頁',
    'outlet': '服務網點',
    'outletInfo': '網點資訊',
    'outletXq': '網點詳情',
    'outletDesc': '網點介紹',
    'outletAddress': '網點地址',
    'yyfw': '預約服務',
    'yydxq': '預約單詳情',
    'orderNumber': '訂單號',
    'cardNumber': '證件號',
    'orderCode': '預約碼',
    'uploadPay': '上傳支付證明',
    'uploadClick': '點擊上傳',
    'searchcx': '査詢',
    'placeOrder': '請輸入要査詢的訂單號',
    'placeCard': '請輸入預約人身份證號',
    'conUser': '連絡人',
    'conMobile': '聯繫電話',
    'yyUserinfo': '預約人資訊',
    'yuyue': '預約',
    'yyUser': '預約人',
    'yyUserHint': '預約人（個人資訊必須與證件號相符）',
    'yyTime': '預約時間',
    'sex1': '男',
    'sex2': '女',
    'docCheck': '醫生檢查單',
    'docCheckHint': '醫生檢查單（最多3個檔案）',
    'confirm': '確定',
    'cancel': '取消',
    'noSearchHint': '未査詢到相關資訊！',
    'alipay': '支付寶',
    'paypal': 'PayPal',
    'offline': '線下支付',
    'firstname': '英文姓氏',
    'lastname': '英文名字',
    'gender': '性别',
    'birthday': '出生日期',
    'carid': '香港身份證或往來港澳通行證',
    'referral': '醫生轉介信發出日期',
    'referralHint': '請選擇醫生轉介信發出日期',
    'lastnameHint': '請輸入英文姓氏',
    'firstnameHint': '請輸入英文名字',
    'genderHint': '選擇性別',
    'birthdayHint': '選擇出生日期',
    'caridHint': '香港身份證A123456(7)，請輸入A1234567',
    'mobileHint': '請輸入聯繫電話',
    'mobileCodeGet': '獲取驗證碼',
    'mobileCode': '驗證碼',
    'mobileCodeHint': '輸入驗證碼',
    'extend': '識別碼',
    'extendHint': '請輸入識別碼(選填)',
    'clearHistory': '清除記錄',
    'yyProject': '預約項目',
    'project': '項目',
    'hospital': '醫管局轉介',
    'selectTime': '選擇時間',
    'addService': '添加服務',
    'checkAgree': '我已閱讀並同意',
    'checkAgreeClick': '隱私政策、有關收集個人資料的目的、版權告示及免責明',
    'checkAgreeClickMsg': '請閱讀並同意隱私政策、有關收集個人資料的目的、版權告示及免責明',
  },
  order: {
    'status0': '待支付',
    'status1': '稽核中',
    'status2': '稽核失敗',
    'status3': '已支付',
    'status4': '已完成',
    'yyoutlet': '預約網點',
    'reason': '原因',
    'hint1': '您的訂單尚未支付',
    'hint2': '付款資訊正在稽核中',
    'hint3': '請您在預約時間前往預約網點獲取服務',
    'hint4': '服務已完成',
    'orderInfo': '訂單資訊',
    'createTime': '下單時間',
    'payTime': '支付時間',
    'payChannel': '支付方式',
    'payChannelHint': '選擇支付方式',
    'total': '合計',
    'price0': '市場價',
    'price1': '醫管局轉介價',
    'mustPay': '應付',
    'searchTotal': '共找到',
    'searchAppend': '個服務',
    'searchOutletAppend': '家服務網點',
    'yySuccess': '預約成功',
    'yyFailed': '預約失敗',
    'yySuccessHint': '請在預約時間前往對應服務網點獲取服務！',
    'yyOfflineSuccessHint1': '請根據下方提示及時支付款項，',
    'yyOfflineSuccessHint2': '並于預約時間前往指定的服務網點獲取服務！',
    'payInfo': '付款資訊',
    'yyMoney': '金額',
    'toOrderDetail': '查看訂單詳情',
  }
}

export default zhCT

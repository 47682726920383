<template>
  <div class="footer">
    <div class="top" :class="screen ==3? 'screen-small':''">
      <div class="left">
        <div class="logo">
          <img src="../assets/img/footerLogo.png" alt="" style="position: relative;width: 120px">
        </div>
      </div>
      <dl style="display: flex;justify-content: space-between;align-items: center;">
        <!-- 用户协议 -->
        <dt @click="user">{{ $t('messages.userProtocol') }}</dt>
        <!-- 隐私政策 -->
        <dt @click="stytem">{{ $t('messages.privacyPolicy') }}</dt>
        <!-- 关于我们 -->
        <dt @click="aboutUs">{{ $t('messages.aboutUs') }}</dt>
      </dl>
    </div>

    <div class="bouttom">
      <div class="buttom" :class="screen==3?'buttom-small': ''">
        <span>{{$t('messages.companyNumber1')}}</span>
      </div>
    </div>

  </div>
</template>
<script setup>
import router from '@/router';
import { useRouter } from 'vue-router';
import { computed } from "vue";
import { useStore } from "@/store";
const store = useStore();
const screen = computed(() => store.state.app.screen_level)

function user () {
  router.push({ path: 'usercon' })
}
function stytem () {
  router.push({ path: '/popolicy' })
}
function aboutUs () {
  router.push({ path: '/aboutUs' })
}
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  background: #ededed;
  margin-top: 30px;
}

.top {
  padding: 12px 0 0;
  display: flex;
  justify-content: center;
}

.screen-small {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.left {
  width: auto;
  height: 35px;
  /* padding: 8px 0; */
}

.logo {
  width: auto;
  height: 35px;
  border-radius: 4px;
}

.bouttom {
  width: 100%;
  border-top: 1px solid #dddddd;
  display: flex;
  justify-content: center;
}

.buttom {
  width: auto;
  padding: 10px;
  margin: 0 auto;
  display: flex;
}

.buttom-small {
  flex-direction: column;
  align-items: center;
}

span {
  font-size: 12px;
  color: #999999;
  display: inline-block;
}

dt {
  cursor: pointer;
}
</style>
